import router from '@/router';

export function redirect(url: string) : void {    
    try {
        if (url && (url.startsWith('/') || url.startsWith(window.location.origin))) {
            router.replace(url.replace(window.location.origin, ''));
        } else {
            window.location.replace(url);
        }
    } catch (e) {
        console.log("Error on redirect: ", e);
    }
}