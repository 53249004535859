/* eslint-disable */
class Enum {

    IsValid(enumType: any, enumValue: any): boolean {
        for (const k in enumType) if (enumType[k] == enumValue) return true;
        return false;
    }

    EnumString(enumType: any, enumValue: any): string {

        if (enumType == null)
            throw new Error("Argument 'enumType' is null or undefined.");

        if (enumValue == null)
            throw new Error("Argument 'enumValue' is null or undefined.");

        for (const k in enumType) 
            if (enumType[k] == enumValue) 
                return k;

        return '';
    }

    In(needle: any, ...haystack: any[]): boolean {

        if (needle == null)
            throw new Error("Argument 'needle' is null or undefined.");

        if (haystack == null)
            throw new Error("Argument 'haystack' is null or undefined.");

        if (haystack.length <= 0)
            throw new Error("Argument 'haystack' is empty.");

        return haystack.includes(needle);
    }
}

export default new Enum();